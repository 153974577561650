<template>
  <div>
    <div class="top"></div>
    <div class="mid">
      <slot></slot>
    </div>
    <div class="bottom"></div>
  </div>
</template>
<script>
export default {}
</script>
<style scoped>
.mid {
  background-image: url(/cream-mid.png);
  background-repeat: no-repeat;
  background-position-x: 2px;
  width: 100%;
  background-size: 94.2% 100%;
  margin-left: 2%;
}
.top {
  background-image: url('/cream-top.png');
  height: 60.5px;
  width: 100%;
  background-size: 96% 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.bottom {
  background-image: url('/cream-bottom.png');
  height: 73px;
  margin-top: -1px;
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 96% 100%;
}
</style>
